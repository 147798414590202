import React, { createContext, useEffect, useRef, useState } from "react";
import { createTValue, getCookie } from "@utils/utils";

const defaultState = {};

const AppContext = createContext(defaultState);

const AppProvider = ({ children }) => {
  const headerRef = useRef(null);

  useEffect(() => {
    const storeUrlParamsInCookie = () => {
      const optanonEvent = window.dataLayer?.find(
        (item) => item.event === "OptanonLoaded"
      );
  
      if (!optanonEvent?.OnetrustActiveGroups?.includes("C0007")) return;
  
      const cookieValue = getCookie("t");
      const searchParams = new URLSearchParams(window.location.search);
  
      if (searchParams.toString() || !cookieValue) {
        const urlParameters = createTValue(searchParams);
        document.cookie = `t=${urlParameters}; path=/; SameSite=Lax`;
      }
    };
  
    storeUrlParamsInCookie();
  }, []);
  


  // State for ExitModal
  const [showExitModal, setShowExitModal] = useState(false);
  const [exitUrl, setExitUrl] = useState("");

  // State for PepExitModal
  const [showPepExitModal, setShowPepExitModal] = useState(false);
  const [pepExitUrl, setPepExitUrl] = useState("");

  // Functions to control ExitModal
  const openExitModal = (url) => {
    setExitUrl(url);
    setShowExitModal(true);
  };

  const closeExitModal = () => {
    setShowExitModal(false);
    setExitUrl("");
  };

  // Functions to control PepExitModal
  const openPepExitModal = (url) => {
    setPepExitUrl(url);
    setShowPepExitModal(true);
  };

  const closePepExitModal = () => {
    setShowPepExitModal(false);
    setPepExitUrl("");
  };

  const store = {
    headerRef,
    showExitModal,
    exitUrl,
    openExitModal,
    closeExitModal,
    showPepExitModal,
    pepExitUrl,
    openPepExitModal,
    closePepExitModal,
  };

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

export default AppContext;
export { AppProvider };
